import React from "react";

import { Helmet } from "react-helmet";

import "./home.css";

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Cognizen</title>
        <meta property="og:title" content="Cognizen" />
      </Helmet>
      <div
        data-role="Header"
        className="home-navbar-container home-navbar-container"
      >
        <div className="home-navbar">
          <div className="home-logo">
            <div class="flex-container">
              <a href="#home-page">
                <img alt="image" src="/logo.svg" className="home-image" />
              </a>
            </div>
          </div>
          <div className="home-links-container">
            <a href="#features" className="home-link home-link Anchor">
              Who are we?
            </a>
            <a href="#products" className="home-link1 home-link1 Anchor">
              Products
            </a>
            <a href="#contact" className="home-link3 home-link3 Anchor">
              Contact
            </a>
          </div>
          <div className="home-cta-container">
            <div data-role="BurgerMenu" className="home-burger-menu">
              <svg viewBox="0 0 1024 1024" className="home-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
          </div>
          <div data-role="MobileMenu" className="home-mobile-menu">
            <div className="home-top">
              <img alt="image" src="/logo.jpg" className="home-image1" />
              <div data-role="CloseMobileMenu" className="home-container1">
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="home-mid">
              <div className="home-links-container1">
                <a href="#features" className="home-link home-link4 Anchor">
                  Who are we?
                </a>
                <a href="#products" className="home-link1 home-link5 Anchor">
                  Products
                </a>
                <a href="#contact" className="home-link3 home-link7 Anchor">
                  Contact
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section-separator"></div>
      <div id="home-page" className="home-heading-container">
        <h2 className="home-text04 Section-Heading">cognizen</h2>
        <span className="home-text05 Section-Text">
          AI Revolution with purpose, innovation, and integrity.
        </span>
      </div>
      <div id="features" className="home-features">
        <div className="home-cards-container">
          <div className="home-feature-card">
            <img alt="image" src="/icon1.png" className="home-image3" />
            <span className="home-heading1 Card-Heading">
              Beyond Ordinary Vision
            </span>
            <span className="home-text06 Card-Text">
              At Cognizen, we harness the transformative power of artificial
              intelligence. But our mission goes beyond delivering cutting-edge
              solutions. We're deeply invested in democratizing AI, ensuring
              that its benefits are accessible to everyone, regardless of
              background or expertise. Our commitment is to shape a future where
              AI not only assists but also amplifies human potential. By making
              this revolutionary technology universally available, we aim to
              elevate every facet of our lives.
            </span>
          </div>
          <div className="home-feature-card1">
            <img alt="image" src="/icon2.png" className="home-image4" />
            <span className="home-heading2 Card-Heading">
              AI Solutions of tomorrow
            </span>
            <span className="home-text07 Card-Text">
              <strong>Model-Based AI Development: </strong>Crafting tailored AI
              solutions that fit the unique demands of each domain.
            </span>
            <span className="home-text07 Card-Text">
              <strong>High-Performance Analytics: </strong>Driving
              decision-making with AI-powered detection, estimation, and
              classification tools.
            </span>
            <span className="home-text07 Card-Text">
              <strong>Personalized EdTech Solutions:</strong> Transforming the
              educational landscape by offering AI-driven content and advanced
              learning tracking tools.
            </span>
          </div>
          <div className="home-feature-card2">
            <img alt="image" src="/icon3.png" className="home-image5" />
            <span className="home-heading3 Card-Heading">
              Driven by Passion and Tenacity
            </span>
            <span className="home-text08 Card-Text">
              <strong>Cutting-Edge Research: </strong> We remain at the cutting
              edge, ensuring our solutions are always ahead of the curve.
            </span>
            <span className="home-text08 Card-Text">
              <strong>Iterative Development: </strong>Agile in our approach, we
              continually hone our solutions, informed by real-world insights
              and the ever-evolving landscape of demands.
            </span>
            <span className="home-text08 Card-Text">
              <strong>Accessible AI: </strong>We don't just build AI; we make it
              accessible. We ensure our solutions are fair, transparent, and
              beneficial for all.
            </span>
          </div>
        </div>
      </div>
      <div id="products" className="home-products">
        <div className="home-section-separator1"></div>
        <div h2 className="home-text-products Section-Heading">
          Products
        </div>
        <div id="products" className="home-products">
          <div className="home-product-card">
            <div className="home-card-content">
              <h3 className="home-text09 BigCard-Heading">EAZY</h3>
              <span className="home-text10 Card-Text">
                Discover a new dimension in teaching with EAZY, your AI-powered
                educational ally. Effortlessly create tests from a vast pool of
                questions, meticulously crafted for distinct topics and learning
                outcomes. Trust our AI to intuitively identify and bridge gaps
                in understanding with pertinent questions. EAZY simplifies the
                journey to concept mastery, ensuring an optimized learning
                experience.{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </div>
          </div>
          <div className="home-product-card1">
            <div className="home-card-content1">
              <h3 className="home-text11 BigCard-Heading">EduZen</h3>
              <span className="home-text12 Card-Text">
                Embrace the future of math education with EduZen, our collection
                of cutting-edge educational tools. We aim to provide
                comprehensive personalized assistance and analytics using AI for
                students. More features include exercise recommendation,
                reinforcing the understanding of every topic. Transforming the
                once-daunting world of math into an accessible and interactive
                experience, right at students' fingertips.{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="contact" className="home-contact home-contact">
        <div className="home-content-container">
          <h2 className="home-text24 Section-Heading">Where to find us:</h2>
          <div className="home-locations-container">
            <div className="home-location-1">
              <span className="home-heading4">Headquarters:</span>
              <div className="home-adress">
                <svg viewBox="0 0 1024 1024" className="home-icon40">
                  <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
                </svg>
                <div className="Section-Text">
                  <div>Cognizen Arge Mühendislik ve Yazılım Anonim Şirketi</div>
                  <div>
                    Bilkent Cyberpark, Üniversiteler Mah., Şehit Mustafa
                    Tayyarcan Cad., Tepe Binası Blok No: 5, Çankaya, Ankara,
                    Türkiye
                  </div>
                </div>
              </div>
              <div className="home-email">
                <svg viewBox="0 0 1024 1024" className="home-icon42">
                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
                <span className="Section-Text">info@cognizen.ai</span>
              </div>
              <div className="home-privacy-policy">
                <a
                  href="YDS_Hocam_privacy_policy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="home-link"
                >
                  YDS Hocam Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
